import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import CardFormFilter from "./CardFormFilter";
import styles from "./ServiceCard.module.css";

const ServiceCard = () => {
  const navigate = useNavigate();

  const onButtonClick = useCallback(() => {
    navigate("/focusmatrimoniquasifinal");
  }, [navigate]);

  const onButton1Click = useCallback(() => {
    navigate("/focus18esimiquasifinal");
  }, [navigate]);

  const onButton2Click = useCallback(() => {
    navigate("/focusaziendaliquasifinal");
  }, [navigate]);

  const onButton12Click = useCallback(() => {
    navigate("/focusprivatiquasifinal");
  }, [navigate]);

  return (
    <div className={styles.serviziWrapper}>
      <div className={styles.servizi}>
        <h2 className={styles.iMieiServizi}>I miei servizi</h2>
        <CardFormFilter
          imageSize="/hero-matrimoni.jpg"
          eventType="Matrimoni"
          imageDescription="/hero-18esimi.jpg"
          eventImageUrl="18esimi"
          onButton1Click={onButtonClick}
          onButton2Click={onButton1Click}
        />
        <CardFormFilter
          imageSize="/hero-aziendali.jpg"
          eventType="Eventi aziendali"
          imageDescription="/hero-privati.jpg"
          eventImageUrl="Eventi Privati"
          propTop="0%"
          propHeight="100%"
          propBottom="0%"
          propMaxHeight="100%"
          onButton1Click={onButton2Click}
          onButton2Click={onButton12Click}
        />
      </div>
    </div>
  );
};

export default ServiceCard;
