import { useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import DropdownmenuServizi from "../components/DropdownmenuServizi";
import PortalPopup from "../components/PortalPopup";
import Navbar from "../components/Navbar";
import Property1Variant from "../components/Property1Variant";
import Prefooter1 from "../components/Prefooter";
import Footer1 from "../components/Footer";
import styles from "./ThankYouPageQuasiFinal.module.css";
import Banner from "../components/banner";

const ThankYouPageQuasiFinal = () => {
  const [isDropdownmenuServiziPopupOpen, setDropdownmenuServiziPopupOpen] =
    useState(false);
  const navigate = useNavigate();

  const onButtonClick = useCallback(() => {
    navigate("/contattiquasifinal");
  }, [navigate]);

  const onButton1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onButton2Click = useCallback(() => {
    navigate("/contattiquasifinal");
  }, [navigate]);

  return (
    <div className={styles.thankYouPageQuasiFinal}>
      <Banner></Banner>
      <Navbar
        navbarMargin="0"
        navbarBackgroundColor="#222"
        navbarAlignSelf="stretch"
        navbarWidth="unset"
        chiSonoColor="#fff"
        serviziColor="#fff"
        galleryColor="#fff"
        hamburgerIconBackgroundColor="transparent"
        onButtonClick={onButtonClick}
      />
      <div className={styles.thankYouPageQuasiFinalInner}>
        <div className={styles.graziePerLaTuaRichiestaParent}>
          <b className={styles.graziePerLaContainer}>
            <p className={styles.graziePerLa}>Grazie per la tua richiesta!</p>
          </b>
          <div className={styles.graziePerLaContainer}>
            Un membro del nostro team si metterà presto in contatto con te
          </div>
          <Property1Variant
            button="Torna al sito"
            property1Variant2Border="none"
            property1Variant2BackgroundColor="#cea350"
            property1Variant2Width="unset"
            buttonDisplay="inline-block"
            buttonTextDecoration="unset"
            buttonFontWeight="unset"
            onButtonClick={onButton1Click}
          />
        </div>
      </div>
      <Prefooter1 onButtonClick={onButton2Click} />
      <Footer1 footerHeight="453px" />
    </div>
  );
};

export default ThankYouPageQuasiFinal;
