import { useState, useCallback } from "react";
import RispostaPagamenti from "../components/RispostaPagamenti";
import PortalPopup from "../components/PortalPopup";
import RispostaTempoContatto from "../components/RispostaTempoContatto";
import RispostaTipologiaServizi from "../components/RispostaTipologiaServizi";
import RispostaPackMatrimonio from "../components/RispostaPackMatrimonio";
import RispostaRepertorio from "../components/RispostaRepertorio";
import RispostaCaparra from "../components/RispostaCaparra";
import { useNavigate, } from "react-router-dom";
import MainHeader from "../components/MainHeader";
import Property1Default from "../components/Property1Default";
import Prefooter from "../components/Prefooter";
import Footer from "../components/Footer";
import styles from "./ContattiQuasiFinal.module.css";
import React, { useRef } from "react";
import { firestore } from "../firebase";
import { addDoc, collection } from "@firebase/firestore"
import MyForm from "../components/formGoogleCalendar";

const ContattiQuasiFinal = () => {
  const messageRef = useRef();
  const nameRef = useRef();
  const telRef = useRef();
  const mailRef = useRef();

  const handleSave = async (e) => {
    e.preventDefault();

    // Validazione base
    if (!messageRef.current.value || !nameRef.current.value || !telRef.current.value || !mailRef.current.value) {
      console.log("Compila tutti i campi!");
      return;
    }

    let data = {
      message: messageRef.current.value,
      name: nameRef.current.value,
      tel: telRef.current.value,
      mail: mailRef.current.value,
    };

    try {
      // URL della funzione proxy distribuita su Firebase
      const proxyUrl = "https://us-central1-lorenzogorettisitotesi.cloudfunctions.net/firestoreProxy";

      // Chiamata a Firestore attraverso la funzione proxy
      const response = await fetch(proxyUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        navigate("/thankyoupagequasifinal");
      } else {
        console.log("Errore nell'aggiunta del documento:", await response.json());
      }
    } catch (e) {
      console.log("Errore nella richiesta:", e);
    }
  };

  const [isRispostaPagamentiPopupOpen, setRispostaPagamentiPopupOpen] =
    useState(false);
  const [isRispostaTempoContattoPopupOpen, setRispostaTempoContattoPopupOpen] =
    useState(false);
  const [
    isRispostaTipologiaServiziPopupOpen,
    setRispostaTipologiaServiziPopupOpen,
  ] = useState(false);
  const [
    isRispostaPackMatrimonioPopupOpen,
    setRispostaPackMatrimonioPopupOpen,
  ] = useState(false);
  const [isRispostaRepertorioPopupOpen, setRispostaRepertorioPopupOpen] =
    useState(false);
  const [isRispostaCaparraPopupOpen, setRispostaCaparraPopupOpen] =
    useState(false);
    
  const navigate = useNavigate();

  const onButtonClick = useCallback(() => {
    navigate("/contattiquasifinal");
  }, [navigate]);

  const onButton2Click = useCallback(() => {
    navigate('/ThankYouPageQuasiFinal');
  }, [navigate]);

  const openRispostaPagamentiPopup = useCallback(() => {
    setRispostaPagamentiPopupOpen(true);
  }, []);

  const closeRispostaPagamentiPopup = useCallback(() => {
    setRispostaPagamentiPopupOpen(false);
  }, []);

  const openRispostaTempoContattoPopup = useCallback(() => {
    setRispostaTempoContattoPopupOpen(true);
  }, []);

  const closeRispostaTempoContattoPopup = useCallback(() => {
    setRispostaTempoContattoPopupOpen(false);
  }, []);

  const openRispostaTipologiaServiziPopup = useCallback(() => {
    setRispostaTipologiaServiziPopupOpen(true);
  }, []);

  const closeRispostaTipologiaServiziPopup = useCallback(() => {
    setRispostaTipologiaServiziPopupOpen(false);
  }, []);

  const openRispostaPackMatrimonioPopup = useCallback(() => {
    setRispostaPackMatrimonioPopupOpen(true);
  }, []);

  const closeRispostaPackMatrimonioPopup = useCallback(() => {
    setRispostaPackMatrimonioPopupOpen(false);
  }, []);

  const openRispostaRepertorioPopup = useCallback(() => {
    setRispostaRepertorioPopupOpen(true);
  }, []);

  const closeRispostaRepertorioPopup = useCallback(() => {
    setRispostaRepertorioPopupOpen(false);
  }, []);

  const openRispostaCaparraPopup = useCallback(() => {
    setRispostaCaparraPopupOpen(true);
  }, []);

  const closeRispostaCaparraPopup = useCallback(() => {
    setRispostaCaparraPopupOpen(false);
  }, []);

  return (
    <>
      <div className={styles.contattiQuasiFinal}>
        <MainHeader
          sectionTitle="Contatti"
          descriptionText="La festa dei tuoi sogni è solo ad un click di distanza"
          contentHeroBackgroundColor="#222"
          contentHeroBackgroundImage="unset"
          contentHeroBackgroundSize="unset"
          contentHeroBackgroundRepeat="unset"
          contentHeroBackgroundPosition="unset"
          chevronDownUndefinedGlyColor="#fff"
          buttonColor="#fff"
          contentHeroDisplay="inline-block"
          onButtonClick={onButtonClick}
        />
        <div className={styles.categoriessection}>
          <div className={styles.categoriescolumn}>
            <h2 className={styles.prossimeDate}>Prossime date</h2>
            <div className={styles.categoryEvents}>
              <p className={styles.p}>29/11/24</p>
              <div className={styles.actionEvents}>
                <div className={styles.titles}>
                  <p className={styles.title}>Desio</p>
                </div>
                <p className={styles.title}>Movie Club</p>
                <p className={styles.title}>Discoteca</p>
              </div>
            </div>
            <img className={styles.separatorIcon} alt="" src="/separator.svg" />
            <div className={styles.categoryEvents}>
              <p className={styles.p}>29/11/24</p>
              <div className={styles.actionEvents}>
                <div className={styles.titles}>
                  <p className={styles.title}>Lissone</p>
                </div>
                <p className={styles.title}>Moonlight Club</p>
                <p className={styles.title}>Discoteca</p>
              </div>
            </div>
            <img className={styles.separatorIcon} alt="" src="/separator.svg" />
            <div className={styles.categoryEvents}>
              <p className={styles.p}>30/11/24</p>
              <div className={styles.actionEvents}>
                <div className={styles.titles}>
                  <p className={styles.title}>Castellanza</p>
                </div>
                <p className={styles.title}>Ritual Disco Club</p>
                <p className={styles.title}>Discoteca</p>
              </div>
            </div>
            <img className={styles.separatorIcon} alt="" src="/separator.svg" />
            <div className={styles.categoryEvents}>
              <p className={styles.p}>06/12/24</p>
              <div className={styles.actionEvents}>
                <div className={styles.titles}>
                  <p className={styles.title}>Lissone</p>
                </div>
                <p className={styles.title}>Moonlight Club</p>
                <p className={styles.title}>Discoteca</p>
              </div>
            </div>
            <img className={styles.separatorIcon} alt="" src="/separator.svg" />
          </div>
        </div>
        <MyForm></MyForm>
        <div className={styles.getInTouch}>
          <h2 className={styles.getInTouch1}>Get in touch</h2>
          <div className={styles.frameParent}>
            <div className={styles.homeUndefinedGlyphUndeParent}>
              <img
                className={styles.homeUndefinedGlyphUnde}
                alt=""
                src="/home--undefined--glyph-undefined.svg"
              />
              <p className={styles.viaDeiTigli}>
                Via dei tigli 4, Besana Brianza
              </p>
            </div>
            <div className={styles.phoneUndefinedGlyphUndParent}>
              <img
                className={styles.homeUndefinedGlyphUnde}
                alt=""
                src="/phone--undefined--glyph-undefined.svg"
              />
              <p className={styles.p4}>3338484875</p>
            </div>
            <div className={styles.mailUndefinedGlyphUndeParent}>
              <img
                className={styles.mailUndefinedGlyphUnde}
                alt=""
                src="/mail--undefined--glyph-undefined.svg"
              />
              <p className={styles.gorettilollogmailcom}>
                goretti.lollo@gmail.com
              </p>
            </div>
          </div>

          <div className={styles.form}>
            <div className={styles.comePossiamoAiutartiParent}>
              <h3 className={styles.comePossiamoAiutartiContainer}>
                <p className={styles.comePossiamoAiutarti}>
                  Hai ancora dei dubbi?
                </p>
              </h3>
              <p className={styles.mandaciLaTuaContainer}>
                <span className={styles.mandaciLaTua}>
                  Mandaci la tua richiesta per avere un preventivo gratuito.
                  Cercheremo di rispondere al meglio alle tue esigenze.
                </span>
              </p>
              <p className={styles.oraToccaAContainer}>
                <span className={styles.mandaciLaTua}>
                  <span>Ora tocca a te.</span>
                </span>
                <span className={styles.mandaciLaTua}>
                  <b>La festa dei tuoi sogni è a solo un click di distanza</b>
                </span>
              </p>
            </div>
            <form className={styles.contactForm} onSubmit={handleSave}>
              <div className={styles.frameGroup}>
                <div className={styles.ilTuoNomeLaTuaAziendaParent}>
                  <p className={styles.ilTuoNome}>Il tuo nome / La tua azienda</p>
                  <input
                    className={styles.frameChild}
                    placeholder="Nome/Azienda.."
                    type="text"
                    ref={nameRef}
                  />
                </div>
                <div className={styles.frameContainer}>
                  <div className={styles.emailParent}>
                    <p className={styles.ilTuoNome}>Email</p>
                    <input
                      className={styles.frameChild}
                      placeholder="Email.."
                      type="text"
                      ref={mailRef}
                    />
                  </div>
                  <div className={styles.emailParent}>
                    <p className={styles.ilTuoNome}>Telefono</p>
                    <input
                      className={styles.frameChild}
                      placeholder="Il tuo Numero.."
                      type="text"
                      ref={telRef}
                    />
                  </div>
                </div>
                <div className={styles.laTuaRichiestaParent}>
                  <p className={styles.ilTuoNome}>La tua richiesta</p>
                  <input
                    className={styles.rectangleInput}
                    placeholder="Dicci di cosa hai bisogno.."
                    type="text"
                    ref={messageRef}
                  />
                </div>
                <div className={styles.privacyCheckboxContainer}>
                  <input
                    type="checkbox"
                    id="privacyCheckbox"
                    className={styles.privacyCheckbox}
                    required
                  />
                  <label htmlFor="privacyCheckbox" className={styles.privacyLabel}>
                    Acconsento al trattamento dei miei dati personali in conformità con la normativa sulla privacy.
                  </label>
                </div>
                <button className={styles.buttonForm} type="submit">
                  Mandaci la tua richiesta
                </button>
              </div>
            </form>

          </div>
        </div>
        <div className={styles.bloccoFaq}>
          <div className={styles.categoriescolumn}>
            <h2 className={styles.prossimeDate}>Frequently asked question!</h2>
            <div className={styles.category}>
              <p className={styles.p}>01</p>
              <div className={styles.action}>
                <div className={styles.titles}>
                  <p className={styles.title4}>Come posso pagare?</p>
                </div>
                <button
                  className={styles.arrow}
                  onClick={openRispostaPagamentiPopup}
                >
                  <img className={styles.path3Icon} alt="" src="/path-3.svg" />
                  <img className={styles.pathIcon} alt="" src="/path.svg" />
                </button>
              </div>
            </div>
            <img className={styles.separatorIcon} alt="" src="/separator.svg" />
            <div className={styles.category}>
              <p className={styles.p}>02</p>
              <div className={styles.action}>
                <div className={styles.titles}>
                  <p className={styles.title}>
                    Entro quando devo mettermi in contatto con te?
                  </p>
                </div>
                <button
                  className={styles.arrow}
                  onClick={openRispostaTempoContattoPopup}
                >
                  <img className={styles.path3Icon} alt="" src="/path-3.svg" />
                  <img className={styles.pathIcon} alt="" src="/path.svg" />
                </button>
              </div>
            </div>
            <img className={styles.separatorIcon} alt="" src="/separator.svg" />
            <div className={styles.category}>
              <p className={styles.p}>03</p>
              <div className={styles.action}>
                <div className={styles.titles}>
                  <p className={styles.title}>Che servizi realizzi?</p>
                </div>
                <button
                  className={styles.arrow}
                  onClick={openRispostaTipologiaServiziPopup}
                >
                  <img className={styles.path3Icon} alt="" src="/path-3.svg" />
                  <img className={styles.pathIcon} alt="" src="/path.svg" />
                </button>
              </div>
            </div>
            <img className={styles.separatorIcon} alt="" src="/separator.svg" />
            <div className={styles.category}>
              <p className={styles.p}>04</p>
              <div className={styles.action}>
                <div className={styles.titles}>
                  <p className={styles.title}>
                    Cosa include il pack matrimonio?
                  </p>
                </div>
                <button
                  className={styles.arrow}
                  onClick={openRispostaPackMatrimonioPopup}
                >
                  <img className={styles.path3Icon} alt="" src="/path-3.svg" />
                  <img className={styles.pathIcon} alt="" src="/path.svg" />
                </button>
              </div>
            </div>
            <img className={styles.separatorIcon} alt="" src="/separator.svg" />
            <div className={styles.category}>
              <p className={styles.p}>05</p>
              <div className={styles.action}>
                <div className={styles.titles}>
                  <p className={styles.title}>Che genere di musica proponi?</p>
                </div>
                <button
                  className={styles.arrow}
                  onClick={openRispostaRepertorioPopup}
                >
                  <img className={styles.path3Icon} alt="" src="/path-3.svg" />
                  <img className={styles.pathIcon} alt="" src="/path.svg" />
                </button>
              </div>
            </div>
            <img className={styles.separatorIcon} alt="" src="/separator.svg" />
            <div className={styles.category}>
              <p className={styles.p}>06</p>
              <div className={styles.action}>
                <div className={styles.titles}>
                  <p className={styles.title9}>
                    E’ necessaria pagare una caparra di acconto per bloccare la
                    data?
                  </p>
                </div>
                <button
                  className={styles.arrow}
                  onClick={openRispostaCaparraPopup}
                >
                  <img className={styles.path3Icon} alt="" src="/path-3.svg" />
                  <img className={styles.pathIcon} alt="" src="/path.svg" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <Prefooter />
        <Footer />
      </div>
      {isRispostaPagamentiPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeRispostaPagamentiPopup}
        >
          <RispostaPagamenti onClose={closeRispostaPagamentiPopup} />
        </PortalPopup>
      )}
      {isRispostaTempoContattoPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeRispostaTempoContattoPopup}
        >
          <RispostaTempoContatto onClose={closeRispostaTempoContattoPopup} />
        </PortalPopup>
      )}
      {isRispostaTipologiaServiziPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeRispostaTipologiaServiziPopup}
        >
          <RispostaTipologiaServizi
            onClose={closeRispostaTipologiaServiziPopup}
          />
        </PortalPopup>
      )}
      {isRispostaPackMatrimonioPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeRispostaPackMatrimonioPopup}
        >
          <RispostaPackMatrimonio onClose={closeRispostaPackMatrimonioPopup} />
        </PortalPopup>
      )}
      {isRispostaRepertorioPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeRispostaRepertorioPopup}
        >
          <RispostaRepertorio onClose={closeRispostaRepertorioPopup} />
        </PortalPopup>
      )}
      {isRispostaCaparraPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeRispostaCaparraPopup}
        >
          <RispostaCaparra onClose={closeRispostaCaparraPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default ContattiQuasiFinal;
