import Property1Variant from "./Property1Variant";
import styles from "./ContactForm.module.css";

const ContactForm = ({
  onFrameContainerClick,
  onButton1Click,
  onButton2Click,
}) => {
  return (
    <div className={styles.contactFocus}>
      <div className={styles.seiAncoraIndecisoSuCosaFaParent}>
        <p className={styles.seiAncoraIndeciso}>
          Sei ancora indeciso su cosa fare? Sentiti pure libero di guardare la
          mia gallery per capirne di più sui miei eventi passati oppure
          contattami per un preventivo
        </p>
        <div className={styles.buttonParent} onClick={onFrameContainerClick}>
          <Property1Variant
            button="Sfoglia la gallery"
            property1Variant2Border="none"
            property1Variant2BackgroundColor="#cea350"
            property1Variant2Width="275px"
            buttonDisplay="inline-block"
            buttonTextDecoration="unset"
            buttonFontWeight="unset"
            onButtonClick={onButton1Click}
          />
          <Property1Variant
            button="Richiedi un preventivo"
            property1Variant2Border="none"
            property1Variant2BackgroundColor="#cea350"
            property1Variant2Width="357px"
            buttonDisplay="inline-block"
            buttonTextDecoration="unset"
            buttonFontWeight="unset"
            onButtonClick={onButton2Click}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
