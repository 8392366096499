import { useMemo} from "react";
import { useNavigate, } from "react-router-dom";
import { Link } from "react-router-dom";
import styles from "./Footer.module.css";



  const facebookClick = () =>{
    window.open('https://www.facebook.com/lorenzo.goretti.3', '_blank')
  };

  const instagramClick = () =>{
    window.open('https://www.instagram.com/lorenzo__goretti?igsh=NjFwbDJvYTd0MjVl&utm_source=qr', '_blank')
  };

  const tikTokClick = () =>{
    window.open('https://www.tiktok.com/@lorenzogorettidj?_t=8rJVPHNPRME&_r=1', '_blank')
  };


const Footer1 = ({ footerHeight }) => {
  const footerStyle = useMemo(() => {
    return {
      height: footerHeight,
    };
  }, [footerHeight]);

  return (
    <footer className={styles.footer} style={footerStyle}>
      <div className={styles.innerfooter}>
        <div className={styles.fashion}>
          <div className={styles.lorenzoGoretti}>LORENZO GORETTI</div>
          <div className={styles.miChiamoLorenzoContainer}>
            <span>{`Mi chiamo Lorenzo Goretti e da anni offro `}</span>
            <span className={styles.serviziComeDj}>servizi come dj</span>
            <span> per matrimoni, feste private e eventi aziendali</span>
          </div>
          <div className={styles.logos}>
            <div className={styles.rectangleParent}>
              <div className={styles.groupChild} />
              <img className={styles.vectorIcon} alt="" src="/facebook.png" onClick={facebookClick} />
            </div>
            <div className={styles.rectangleParent}>
              <div className={styles.groupChild} />
              <img className={styles.vectorIcon1} alt="" src="/instagram.png" onClick={instagramClick} />
            </div>
            <div className={styles.rectangleParent}>
              <div className={styles.groupChild} />
              <img className={styles.vectorIcon2} alt="" src="/tik-tok.png" onClick={tikTokClick}  />
            </div>
          </div>
        </div>
        <div className={styles.links}>
          <div className={styles.column1}>
            <Link className={styles.servizi} to="/serviziquasifinal">
              Servizi
            </Link>
            <Link className={styles.matrimoni} to="/focusmatrimoniquasifinal">
              Matrimoni
            </Link>
            <Link className={styles.esimi} to="/focus18esimiquasifinal">
              18esimi
            </Link>
            <Link className={styles.matrimoni} to="/focusprivatiquasifinal">
              50esimi
            </Link>
            <Link className={styles.matrimoni} to="/focusaziendaliquasifinal">
              Aziendali
            </Link>
            <Link className={styles.matrimoni} to="/focusprivatiquasifinal">
              Privati
            </Link>
          </div>
          <div className={styles.column1}>
            <div className={styles.linkUtili}>Link utili</div>
            <Link className={styles.chiSono} to="/chisonoquasifinal">
              Chi sono
            </Link>
            <Link className={styles.gallery} to="/galleryquasifinal">
              Gallery
            </Link>
            <Link className={styles.chiSono} to="/contattiquasifinal">
              Contattami
            </Link>
            <Link className={styles.chiSono} to="/contattiquasifinal">
              FAQS
            </Link>
          </div>
          <div className={styles.column3}>
            <b className={styles.contatti}>Contatti</b>
            <div className={styles.div}>+39 3338484875</div>
            <div className={styles.gorettilollogmailcom}>
              goretti.lollo@gmail.com
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer1;
