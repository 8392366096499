import styles from "./RispostaPagamenti.module.css";

const RispostaPackMatrimonio = ({ onClose }) => {
  return (
    <div className={styles.rispostaPagamenti}>
    <div className={styles.iMetodiDiPagamentoAccetatiWrapper}>
      <div className={styles.iMetodiDi}>Il pack matrimonio include il servizio dj per tutta la durata dell’evento.</div>
    </div>
  </div>
  );
};

export default RispostaPackMatrimonio;
