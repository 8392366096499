import styles from "./RispostaPagamenti.module.css";

const RispostaTempoContatto = ({ onClose }) => {
  return (
    <div className={styles.rispostaPagamenti}>
    <div className={styles.iMetodiDiPagamentoAccetatiWrapper}>
      <div className={styles.iMetodiDi}>Non è richiesto un lasso di tempo preciso, ma è consigliato farlo il prima possibile </div>
    </div>
  </div>
  );
};

export default RispostaTempoContatto;
