import styles from "./RispostaPagamenti.module.css";

const RispostaCaparra = ({ onClose }) => {
  return (
    <div className={styles.rispostaPagamenti}>
    <div className={styles.iMetodiDiPagamentoAccetatiWrapper}>
      <div className={styles.iMetodiDi}>Non è richiesto il pagamento di una caparra per bloccare l’evento</div>
    </div>
  </div>
);
};

export default RispostaCaparra;
