import styles from "./RispostaPagamenti.module.css";

const RispostaRepertorio = ({ onClose }) => {
  return (
    <div className={styles.rispostaPagamenti}>
    <div className={styles.iMetodiDiPagamentoAccetatiWrapper}>
      <div className={styles.iMetodiDi}>Commerciale, revival 80/90, musica elettronica.
Si consiglia di vedere la pagina della Biografia per maggiori info.</div>
    </div>
  </div>
  );
};

export default RispostaRepertorio;
