import { useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Property1Variant from "./Property1Variant";
import styles from "./NavbarHidden.module.css";

const NavbarHidden = ({ onClose }) => {
  const navigate = useNavigate();

  const onButtonClick = useCallback(() => {
    navigate("/contattiquasifinal");
  }, [navigate]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div className={styles.navbarHidden} data-animate-on-scroll>
      <div className={styles.logoParent}>
        <Link className={styles.logo} to="/">
          LORENZO GORETTI
        </Link>
        <Link className={styles.chiSono} to="/chisonoquasifinal">
          Chi sono
        </Link>
        <Link className={styles.chiSono} to="/serviziquasifinal">
          Servizi
        </Link>
        <Link className={styles.chiSono} to="/galleryquasifinal">
          Gallery
        </Link>
        <Property1Variant
          button="Contattami"
          property1Variant2Border="none"
          property1Variant2BackgroundColor="#cea350"
          property1Variant2Width="unset"
          buttonDisplay="inline-block"
          buttonTextDecoration="unset"
          buttonFontWeight="bold"
          onButtonClick={onButtonClick}
        />
      </div>
    </div>
  );
};

export default NavbarHidden;
