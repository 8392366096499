import { useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import DropdownmenuServizi from "./DropdownmenuServizi";
import PortalPopup from "./PortalPopup";
import Navbar from "./Navbar";
import Property1Variant from "./Property1Variant";
import styles from "./HeroHomepageDiv.module.css";

const HeroHomepageDiv = () => {
  const [isDropdownmenuServiziPopupOpen, setDropdownmenuServiziPopupOpen] =
    useState(false);
  const navigate = useNavigate();

  const onButtonClick = useCallback(() => {
    navigate("/contattiquasifinal");
  }, [navigate]);

  const onButton1Click = useCallback(() => {
    navigate("/serviziquasifinal");
  }, [navigate]);

  const onRichiediUnPreventivoClick = useCallback(() => {
    navigate("/contattiquasifinal");
  }, [navigate]);

  return (
    <div className={styles.heroHomepage}>
      <Navbar
        navbarMargin="0"
        navbarBackgroundColor="unset"
        navbarAlignSelf="stretch"
        navbarWidth="unset"
        chiSonoColor="#fff"
        serviziColor="#fff"
        galleryColor="#fff"
        hamburgerIconBackgroundColor="transparent"
        onButtonClick={onButtonClick}
      />
      <div className={styles.contentHero}>
        <h1 className={styles.lorenzoGoretti}>Lorenzo Goretti</h1>
        <p className={styles.ilMigliorDj}>Il miglior dj per il tuo evento</p>
        <div className={styles.buttonParent}>
          <Property1Variant
            button="I miei Servizi"
            property1Variant2Border="none"
            property1Variant2BackgroundColor="#cea350"
            property1Variant2Width="unset"
            buttonDisplay="inline-block"
            buttonTextDecoration="unset"
            buttonFontWeight="unset"
            onButtonClick={onButton1Click}
          />
          <button className={styles.btnMobile} onClick={onRichiediUnPreventivoClick}>Richiedi un preventivo</button>
        </div>
      </div>
    </div>
  );
};

export default HeroHomepageDiv;
