import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import HeroHomepageDiv from "../components/HeroHomepageDiv";
import ServiziCard from "../components/ServiziCard";
import Property1Variant from "../components/Property1Variant";
import ReviewCardForm from "../components/ReviewCardForm";
import ControlledCarousel from "../components/CollaboratingCompaniesCard";
import ContactFormCard from "../components/ContactFormCard";
import Prefooter1 from "../components/Prefooter";
import Footer1 from "../components/Footer";
import styles from "./HomeQuasiFinal.module.css";
import Banner from "../components/banner";

const HomeQuasiFinal = () => {

  const navigate = useNavigate();

  const onButtonClick = useCallback(() => {
    navigate("/chisonoquasifinal");
  }, [navigate]);

  const onButton1Click = useCallback(() => {
    navigate("/contattiquasifinal");
  }, [navigate]);

  return (
    <div className={styles.homeQuasiFinal}>
      <Banner></Banner>
      <HeroHomepageDiv />
      <ServiziCard />
      <div className={styles.quoteHomepage}>
        <div className={styles.contentQuote}>
          <div className={styles.containerimg}>
          <img
            className={styles.img94221Icon}
            alt=""
            src="/img-9422-1@2x.png"
          />
          </div>
          <div className={styles.textCta}>
            <p className={styles.fareMusicaEContainer}>
              <span className={styles.fareMusicaE}>
                “Fare musica e far divertire i miei clienti è cio che amo della
                mia vita e che non mi stancherò mai di fare”
              </span>
            </p>
            <Property1Variant
              button="Scopri la mia storia"
              property1Variant2Border="none"
              property1Variant2BackgroundColor="#cea350"
              property1Variant2Width="unset"
              buttonDisplay="inline-block"
              buttonTextDecoration="unset"
              buttonFontWeight="unset"
              onButtonClick={onButtonClick}
            />
          </div>
        </div>
      </div>
      <ReviewCardForm propPadding="var(--padding-131xl) var(--padding-111xl) 0px" />
      <ControlledCarousel />
      <ContactFormCard />
      <Prefooter1 onButtonClick={onButton1Click} />
      <Footer1 footerHeight="unset" />
      
    </div>
  );
};

export default HomeQuasiFinal;
