import React, { useState } from 'react';
import axios from 'axios';
import { formatISO, parseISO } from 'date-fns';
import styles from './formGoogleCalendar.module.css'; // Importa i CSS modulari


const MyForm = () => {
    const [eventName, setEventName] = useState('');
    const [description, setDescription] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();

        // Converti in formato ISO 8601 con fuso orario
        const startTimeISO = formatISO(parseISO(startTime), { representation: 'complete' });
        const endTimeISO = formatISO(parseISO(endTime), { representation: 'complete' });

        const eventData = {
            eventName,
            description,
            startTime: startTimeISO,
            endTime: endTimeISO,
        };

        const token = localStorage.getItem('accessToken'); // Recupera l'access token
        const firebaseFunctionUrl = process.env.REACT_APP_FIREBASE_FUNCTION_URL + '/addEventToCalendar';

        axios.post(firebaseFunctionUrl, eventData, {
            headers: {
                'Authorization': `Bearer ${token}` // Includi l'access token nelle intestazioni
            }
        })
            .then(response => {
                setMessage('Evento creato con successo!');
                console.log('Response data:', response.data);
            })
            .catch(error => {
                setMessage('Si è verificato un errore durante la creazione dell\'evento.');
                console.error('Error details:', error);
                if (error.response) {
                    console.error('Server response:', error.response.data);
                    console.error('Status code:', error.response.status);
                } else if (error.request) {
                    console.error('No response received:', error.request);
                } else {
                    console.error('Error setting up the request:', error.message);
                }
            });
    };

    return (
        <div className={styles.container}>
            <div className={styles.comePossiamoAiutartiParent}>
                <h3 className={styles.comePossiamoAiutartiContainer}>
                    <p className={styles.comePossiamoAiutarti}>
                        Hai già deciso la data del tuo evento?
                    </p>
                </h3>
                <p className={styles.mandaciLaTuaContainer}>
                    <span className={styles.mandaciLaTua}>
                        Prenota il tuo evento direttamente sul mio calendario.<br></br>
                        <b>Nessun costo aggiuntivo.</b>
                    </span>
                </p>
            </div>
            <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.rowVertical}>
                    <label className={styles.label} htmlFor="eventName">Titolo:</label>
                    <input className={styles.input} placeholder='Titolo evento..' type="text" id="eventName" name="eventName" value={eventName} onChange={(event) => setEventName(event.target.value)} required />
                </div>
                <div className={styles.row}>
                    <div className={styles.box}>
                        <label className={styles.label} htmlFor="startTime">Inizio:</label>
                        <input className={styles.input} type="datetime-local" id="startTime" name="startTime" value={startTime} onChange={(event) => setStartTime(event.target.value)} required />
                    </div>
                    <div className={styles.box}>
                        <label className={styles.label} htmlFor="endTime">Fine:</label>
                        <input className={styles.input} type="datetime-local" id="endTime" name="endTime" value={endTime} onChange={(event) => setEndTime(event.target.value)} required />
                    </div>
                </div>
                <div className={styles.rowVertical}>
                    <label className={styles.label} htmlFor="description">Descrizione:</label>
                    <input className={styles.textarea} placeholder='Tipologia evento..' id="description" name="description" value={description} onChange={(event) => setDescription(event.target.value)}></input>
                </div>
                <button className={styles.button} type="submit">Crea evento</button>
                {message && <p className={styles.message}>{message}</p>}
            </form>
        </div>
    );
};

export default MyForm;
