import Property1Variant from "./Property1Variant";
import styles from "./Prefooter.module.css";

const Prefooter1 = ({ onButtonClick }) => {
  return (
    <div className={styles.prefooter}>
      <b className={styles.richiediUnPreventivo}>Richiedi un Preventivo</b>
      <div className={styles.richiediSubitoUnContainer}>
        <p className={styles.richiediSubitoUn}>
          Richiedi subito un preventivo per il tuo evento.
        </p>
        <p className={styles.richiediSubitoUn}>
          Un membro del nostro team si metterà subito in contatto con te.
        </p>
      </div>
      <Property1Variant
        button="Scopri di più"
        property1Variant2Border="none"
        property1Variant2BackgroundColor="#cea350"
        property1Variant2Width="unset"
        buttonDisplay="inline-block"
        buttonTextDecoration="unset"
        buttonFontWeight="unset"
        onButtonClick={onButtonClick}
      />
    </div>
  );
};

export default Prefooter1;
