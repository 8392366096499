import React, { useState, useEffect, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import './Banner.css';

function Banner() {
  const [showCta, setShowCta] = useState(false);
  const navigate = useNavigate();

  const onButtonClick = useCallback(() => {
    navigate("/contattiquasifinal");
  }, [navigate]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth <= 768 && window.scrollY > 100) {
        setShowCta(true);
      } else {
        setShowCta(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`banner ${showCta ? 'show-cta' : ''}`}>
      <p className='banner-text'>Richiedi un preventivo</p>
      <button onClick={onButtonClick} className="cta">Scopri di più</button>
    </div>
  );
}

export default Banner;
