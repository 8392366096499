import { useState, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import DropdownmenuServizi from "./DropdownmenuServizi";
import PortalPopup from "./PortalPopup";
import Property1Variant from "./Property1Variant";
import NavbarHidden from "./NavbarHidden";
import PortalDrawer from "./PortalDrawer";
import styles from "./Navbar.module.css";
import "./Navbar.css";

const Navbar = ({
  navbarMargin,
  navbarBackgroundColor,
  navbarAlignSelf,
  navbarWidth,
  chiSonoColor,
  serviziColor,
  galleryColor,
  hamburgerIconBackgroundColor,
  onButtonClick,
}) => {
  const [isDropdownmenuServiziPopupOpen, setDropdownmenuServiziPopupOpen] =
    useState(false);
  const navbarStyle = useMemo(() => {
    return {
      margin: navbarMargin,
      backgroundColor: navbarBackgroundColor,
      alignSelf: navbarAlignSelf,
    };
  }, [navbarMargin, navbarBackgroundColor, navbarAlignSelf]);

  const chiSonoStyle = useMemo(() => {
    return {
      color: chiSonoColor,
    };
  }, [chiSonoColor]);

  const serviziStyle = useMemo(() => {
    return {
      color: serviziColor,
    };
  }, [serviziColor]);

  const galleryStyle = useMemo(() => {
    return {
      color: galleryColor,
    };
  }, [galleryColor]);

  const hamburgerIconStyle = useMemo(() => {
    return {
      backgroundColor: hamburgerIconBackgroundColor,
    };
  }, [hamburgerIconBackgroundColor]);

  const [isNavbarHiddenOpen, setNavbarHiddenOpen] = useState(false);

  const openDropdownmenuServiziPopup = useCallback(() => {
    setDropdownmenuServiziPopupOpen(true);
  }, []);

  const closeDropdownmenuServiziPopup = useCallback(() => {
    setDropdownmenuServiziPopupOpen(false);
  }, []);

  const openNavbarHidden = useCallback(() => {
    setNavbarHiddenOpen(true);
  }, []);

  const closeNavbarHidden = useCallback(() => {
    setNavbarHiddenOpen(false);
  }, []);
  const [fix, setFix] = useState(false)

  function setFixed() {
    if (window.scrollY >= 1) {
      setFix(true)
    } else {
      setFix(false)
    }
  }
  window.addEventListener("scroll",setFixed)

  return (
    <>
      <nav className={fix ? 'navbar fixed' : 'navbar'} style={navbarStyle}>
        <div className={styles.nav}>
          <Link className={styles.logo} to="/">
            LORENZO GORETTI
          </Link>
          <div className={styles.menuright}>
            <div className={styles.menulinks}>
              <Link
                className={styles.chiSono}
                to="/chisonoquasifinal"
                style={chiSonoStyle}
              >
                Chi sono
              </Link>
              <div className={styles.serviziParent}>
                <Link
                  className={styles.servizi}
                  to="/serviziquasifinal"
                  style={serviziStyle}
                >
                  Servizi
                </Link>
                <button
                  className={styles.chevronDownUndefinedGly}
                  onClick={openDropdownmenuServiziPopup}
                >
                  <img className={styles.vectorIcon} alt="" src="/vector.svg" />
                </button>
              </div>
              <Link
                className={styles.gallery}
                to="/galleryquasifinal"
                style={galleryStyle}
              >
                Gallery
              </Link>
              <Property1Variant
                button="Contattami"
                property1Variant2Border="1px solid var(--primary)"
                property1Variant2BackgroundColor="#cea350"
                property1Variant2Width="unset"
                buttonDisplay="inline-block"
                buttonTextDecoration="unset"
                buttonFontWeight="unset"
                onButtonClick={onButtonClick}
              />
            </div>
            <button
              className={styles.hamburgerIcon}
              onClick={openNavbarHidden}
              style={hamburgerIconStyle}
            >
              <img className={styles.group2Icon} alt="" src="/group2.svg" />
            </button>
          </div>
        </div>
      </nav>
      {isDropdownmenuServiziPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top center"
          onOutsideClick={closeDropdownmenuServiziPopup}
        >
          <DropdownmenuServizi onClose={closeDropdownmenuServiziPopup} />
        </PortalPopup>
      )}
      {isNavbarHiddenOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Left"
          onOutsideClick={closeNavbarHidden}
        >
          <NavbarHidden onClose={closeNavbarHidden} />
        </PortalDrawer>
      )}
    </>
  );
};

export default Navbar;
