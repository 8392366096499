import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import MainHeader from "../components/MainHeader";
import FilteredCardForm1 from "../components/FilteredCardForm1";
import ContactForm from "../components/ContactForm";
import Prefooter1 from "../components/Prefooter";
import Footer1 from "../components/Footer";
import styles from "./FocusPrivatiQuasiFinal.module.css";
import Banner from "../components/banner";

const FocusPrivatiQuasiFinal = () => {
  const navigate = useNavigate();

  const onFrameContainerClick = useCallback(() => {
    // Please sync "contatti" to the project
  }, []);

  const onButtonClick = useCallback(() => {
    navigate("/galleryquasifinal");
  }, [navigate]);

  const onButton1Click = useCallback(() => {
    navigate("/contattiquasifinal");
  }, [navigate]);

  const onButton2Click = useCallback(() => {
    navigate("/contattiquasifinal");
  }, [navigate]);

  return (
    <div className={styles.focusPrivatiQuasiFinal}>
      <Banner></Banner>
      <MainHeader
        sectionTitle="Eventi Privati"
        descriptionText="Rendi la tua festa privata memorabile"
        contentHeroBackgroundColor="unset"
        contentHeroBackgroundImage="url('/hero-privati.jpg')"
        contentHeroBackgroundSize="cover"
        contentHeroBackgroundRepeat="no-repeat"
        contentHeroBackgroundPosition="top"
        chevronDownUndefinedGlyColor="#cea350"
        buttonColor="#fff"
        contentHeroDisplay="inline-block"
        onButtonClick={onButton1Click}
      />
      <div className={styles.toKnow}>
        <div className={styles.cosaDeviSapereParent}>
          <h2 className={styles.cosaDeviSapere}>{`Cosa devi sapere `}</h2>
          <p className={styles.cheSianoMatrimoniContainer}>
            <span className={styles.fondamentaleQuindiAffidars}>
              <span>{`Che siano matrimoni, 18esimi,50esimi, anniversari, feste aziendali, insomma qualsiasi tipo di festa, non devi assolutamente sottovalutare `}</span>
              <span
                className={styles.laComponenteMusicale}
              >{`la componente musicale `}</span>
              <span>se vuoi divertirti tu in primis e anche i tuoi amici.</span>
            </span>
            <span className={styles.fondamentaleQuindiAffidars}>
              <span>{`Fondamentale, quindi, affidarsi ad un professionista che sappia rendere `}</span>
              <span className={styles.laComponenteMusicale}>
                la tua festa indimenticabile.
              </span>
            </span>
          </p>
        </div>
      </div>
      <FilteredCardForm1 />
      <ContactForm
        onFrameContainerClick={onFrameContainerClick}
        onButton1Click={onButtonClick}
        onButton2Click={onButton1Click}
      />
      <Prefooter1 onButtonClick={onButton2Click} />
      <Footer1 footerHeight="unset" />
    </div>
  );
};

export default FocusPrivatiQuasiFinal;
