import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import MainHeader from "../components/MainHeader";
import EventMusicOptions from "../components/EventMusicOptions";
import ContactForm from "../components/ContactForm";
import Prefooter1 from "../components/Prefooter";
import Footer1 from "../components/Footer";
import styles from "./FocusAziendaliQuasiFinal.module.css";
import Banner from "../components/banner";

const FocusAziendaliQuasiFinal = () => {
  const navigate = useNavigate();

  const onButtonClick = useCallback(() => {
    navigate("/contattiquasifinal");
  }, [navigate]);

  const onFrameContainerClick = useCallback(() => {
    // Please sync "contatti" to the project
  }, []);

  const onButton2Click = useCallback(() => {
    navigate("/galleryquasifinal");
  }, [navigate]);

  const onButton1Click = useCallback(() => {
    navigate("/contattiquasifinal");
  }, [navigate]);

  const onButton3Click = useCallback(() => {
    navigate("/contattiquasifinal");
  }, [navigate]);

  return (
    <div className={styles.focusAziendaliQuasiFinal}>
      <Banner></Banner>
      <MainHeader
        sectionTitle="Eventi Aziendali"
        descriptionText="Rendi la tua festa aziendale memorabile"
        contentHeroBackgroundColor="unset"
        contentHeroBackgroundImage="url('/hero-aziendali.jpg')"
        contentHeroBackgroundSize="cover"
        contentHeroBackgroundRepeat="no-repeat"
        contentHeroBackgroundPosition="top"
        chevronDownUndefinedGlyColor="#cea350"
        buttonColor="#fff"
        contentHeroDisplay="inline-block"
        onButtonClick={onButtonClick}
      />
      <div className={styles.toKnow}>
        <div className={styles.cosaDeviSapereParent}>
          <h2 className={styles.cosaDeviSapere}>{`Cosa devi sapere `}</h2>
          <p className={styles.fareTeamBuildingContainer}>
            <span className={styles.fareTeamBuildingConIPropr}>
              <span className={styles.fare}>{`Fare `}</span>
              <span className={styles.teamBuilding}>team building</span>
              <span>
                {" "}
                con i propri colleghi è un fattore determinate nella
                produttività di
              </span>
              <span className={styles.teamBuilding}> un team.</span>
            </span>
            <span className={styles.fareTeamBuildingConIPropr}>
              <span
                className={styles.fare}
              >{`Per rendere la tua festa aziendale e soddisfare i tuoi dipendenti, `}</span>
              <span className={styles.teamBuilding}>
                necessiti di un professionista
              </span>
              <span className={styles.fare}>
                {" "}
                che sappia creare la giusta atmosfera e scelga
              </span>
              <span className={styles.teamBuilding}> la musica giusta </span>
              <span>{`per lasciare i problemi al lavoro e farti passare una serata di divertimento `}</span>
              <span className={styles.teamBuilding}>
                assieme ai tuoi colleghi.
              </span>
            </span>
          </p>
        </div>
      </div>
      <EventMusicOptions
        eventServicesDescription="Possibilità di effettuare  anche servizi come cene cantate per eventi aziendali"
        musicFlexibility="Flessibilità sui generi musicali e sull’effettuare trasferte"
      />
      <ContactForm
        onFrameContainerClick={onFrameContainerClick}
        onButton1Click={onButton2Click}
        onButton2Click={onButton1Click}
      />
      <Prefooter1 onButtonClick={onButton3Click} />
      <Footer1 footerHeight="unset" />
    </div>
  );
};

export default FocusAziendaliQuasiFinal;
