import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import RepertorioCard from "../components/RepertorioCard";
import CollaboratingCompaniesCard from "../components/CollaboratingCompaniesCard";
import TimelineCard from "../components/TimelineCard";
import Prefooter1 from "../components/Prefooter";
import Footer1 from "../components/Footer";
import styles from "./ChiSonoQuasiFinal.module.css";
import Banner from "../components/banner";

const ChiSonoQuasiFinal = () => {
  const navigate = useNavigate();

  const onButtonClick = useCallback(() => {
    navigate("/contattiquasifinal");
  }, [navigate]);

  const onButton2Click = useCallback(() => {
    navigate("/contattiquasifinal");
  }, [navigate]);

  return (
    <div className={styles.chiSonoQuasiFinal}>
      <Banner></Banner>
      <Header
        storyTitle="La mia Storia"
        eventDescription="Una passione diventata realtà"
        onButtonClick={onButtonClick}
      />
      <RepertorioCard />
      <CollaboratingCompaniesCard />
      <TimelineCard />
      <div className={styles.quoteChiSono}>
        <div className={styles.contentQuote}>
          <div className={styles.containerImg}>
          <img
            className={styles.img94221Icon}
            alt=""
            src="/img-9422-1@2x.png"
          />
          </div>
          <div className={styles.textCta}>
            <p className={styles.fareMusicaEContainer}>
              <span className={styles.fareMusicaE}>
                “Fare musica e far divertire i miei clienti è cio che amo della
                mia vita e che non mi stancherò mai di fare”
              </span>
            </p>
          </div>
        </div>
      </div>
      <Prefooter1 onButtonClick={onButton2Click} />
      <Footer1 footerHeight="unset" />
    </div>
  );
};

export default ChiSonoQuasiFinal;
