import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import MainHeader from "../components/MainHeader";
import FormGalleryMatrimoni from "../components/FormGalleryMatrimoni";
import FormGalleryPrivati from "../components/FormGalleryPrivati";
import FormGalleryAziendali from "../components/FormGalleryAziendali";
import AttrezzaturaCard from "../components/AttrezzaturaCard";
import Prefooter1 from "../components/Prefooter";
import Footer1 from "../components/Footer";
import styles from "./GalleryQuasiFinal.module.css";
import Navbar from "../components/Navbar";
import Banner from "../components/banner";

const GalleryQuasiFinal = () => {
  const navigate = useNavigate();

  const onButtonClick = useCallback(() => {
    navigate("/contattiquasifinal");
  }, [navigate]);

  const onButton2Click = useCallback(() => {
    navigate("/contattiquasifinal");
  }, [navigate]);

  return (
    <div className={styles.galleryQuasiFinal}>
      <Banner></Banner>
      <MainHeader
        sectionTitle="Gallery"
        descriptionText="Qui puoi trovare tutto ciò che ti serve sentire e vedere per iniziare a farti un’idea"
        onButtonClick={onButtonClick}
      />
      
      <FormGalleryMatrimoni eventTypeName="Matrimoni" />
      <FormGalleryPrivati
        eventTypeName="Privati"
        videoSource="/privati-2.mp4"
        videoSourceSecond="/privati.mp4">
      </FormGalleryPrivati>
      <FormGalleryAziendali
        eventTypeName="Aziendali"
        videoSource="/campari-melegnano.mp4"
        videoSourceSecond="/aziendale-2.mp4"
        videoSourceThird="/aziendali.mp4">
      </FormGalleryAziendali>

      <AttrezzaturaCard />
      <Prefooter1 onButtonClick={onButton2Click} />
      <Footer1 footerHeight="unset" />
    </div>
  );
};

export default GalleryQuasiFinal;
