import { useMemo } from "react";
import styles from "./Property1Variant.module.css";

const Property1Variant = ({
  button,
  property1Variant2Border,
  property1Variant2BackgroundColor,
  property1Variant2Width,
  buttonDisplay,
  buttonTextDecoration,
  buttonFontSize,
  buttonFontWeight,
  onButtonClick,
  displayMobile
}) => {
  const property1Variant2Style = useMemo(() => {
    return {
      border: property1Variant2Border,
      backgroundColor: property1Variant2BackgroundColor,
      width: property1Variant2Width,
    };
  }, [
    property1Variant2Border,
    property1Variant2BackgroundColor,
    property1Variant2Width,
  ]);

  const button1Style = useMemo(() => {
    return {
      display: buttonDisplay,
      textDecoration: buttonTextDecoration,
      fontSize: buttonFontSize,
      fontWeight: buttonFontWeight,
      displayMobile: displayMobile,
    };
  }, [buttonDisplay, buttonTextDecoration, buttonFontSize, buttonFontWeight, displayMobile]);

  return (
    <div
      className={styles.property1variant2}
      style={property1Variant2Style}
      onClick={onButtonClick}
    >
      <div className={styles.button} style={button1Style}>
        {button}
      </div>
    </div>
  );
};

export default Property1Variant;
