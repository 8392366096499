import { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import DropdownmenuServizi from "./DropdownmenuServizi";
import PortalPopup from "./PortalPopup";
import Navbar from "./Navbar";
import Property1Variant from "./Property1Variant";
import styles from "./Header.module.css";

const Header = ({
  storyTitle,
  eventDescription,
  contentHeroBackgroundColor,
  contentHeroBackgroundImage,
  contentHeroBackgroundSize,
  contentHeroBackgroundRepeat,
  contentHeroBackgroundPosition,
  frameDivColor,
  chevronDownUndefinedGlyColor,
  unaPassioneDiventataHeight,
  onButtonClick,
}) => {
  const [isDropdownmenuServiziPopupOpen, setDropdownmenuServiziPopupOpen] =
    useState(false);
  const heroChiSonoStyle = useMemo(() => {
    return {
      backgroundColor: contentHeroBackgroundColor,
      backgroundImage: contentHeroBackgroundImage,
      backgroundSize: contentHeroBackgroundSize,
      backgroundRepeat: contentHeroBackgroundRepeat,
      backgroundPosition: contentHeroBackgroundPosition,
    };
  }, [
    contentHeroBackgroundColor,
    contentHeroBackgroundImage,
    contentHeroBackgroundSize,
    contentHeroBackgroundRepeat,
    contentHeroBackgroundPosition,
  ]);

  const chiSonoStyle = useMemo(() => {
    return {
      color: frameDivColor,
    };
  }, [frameDivColor]);

  const serviziStyle = useMemo(() => {
    return {
      color: chevronDownUndefinedGlyColor,
    };
  }, [chevronDownUndefinedGlyColor]);

  const laMiaStoriaStyle = useMemo(() => {
    return {
      height: unaPassioneDiventataHeight,
    };
  }, [unaPassioneDiventataHeight]);

  return (
    <div className={styles.heroChiSono} style={heroChiSonoStyle}>
      <div className={styles.contentHero}>
        <Navbar
          navbarMargin="unset"
          navbarBackgroundColor="unset"
          navbarAlignSelf="stretch"
          navbarWidth="unset"
          chiSonoColor="#cea350"
          serviziColor="#fff"
          galleryColor="#fff"
          hamburgerIconBackgroundColor="transparent"
          onButtonClick={onButtonClick}
        />
        <div className={styles.rectangleParent}>
          <div className={styles.frameChild} />
          <h1 className={styles.laMiaStoria} style={laMiaStoriaStyle}>
            {storyTitle}
          </h1>
          <p className={styles.unaPassioneDiventata}>{eventDescription}</p>
        </div>
        <div className={styles.nav}>
          <div className={styles.logo}>Logo</div>
          <div className={styles.menuLinks}>
            <div className={styles.links}>
              <div className={styles.logo}>Chi sono</div>
              <div className={styles.logo}>Servizi</div>
              <div className={styles.logo}>Gallery</div>
            </div>
          </div>
          <div className={styles.buttonParent}>
            <Property1Variant
              button="Contattami"
              property1Variant2Border="unset"
              property1Variant2BackgroundColor="#cea350"
              property1Variant2Width="unset"
              buttonDisplay="inline-block"
              buttonTextDecoration="unset"
              buttonFontWeight="unset"
            />
            <button className={styles.lineHorizontal3Undefined}>
              <img className={styles.vectorIcon} alt="" src="/vector4.svg" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
