import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import MainHeader from "../components/MainHeader";
import EventMusicOptions from "../components/EventMusicOptions";
import ContactForm from "../components/ContactForm";
import Prefooter1 from "../components/Prefooter";
import Footer1 from "../components/Footer";
import styles from "./Focus18esimiQuasiFinal.module.css";
import Banner from "../components/banner";

const Focus18esimiQuasiFinal = () => {
  const navigate = useNavigate();

  const onButtonClick = useCallback(() => {
    navigate("/contattiquasifinal");
  }, [navigate]);

  const onFrameContainerClick = useCallback(() => {
    // Please sync "contatti" to the project
  }, []);

  const onButton2Click = useCallback(() => {
    navigate("/galleryquasifinal");
  }, [navigate]);

  const onButton1Click = useCallback(() => {
    navigate("/contattiquasifinal");
  }, [navigate]);

  const onButton3Click = useCallback(() => {
    navigate("/contattiquasifinal");
  }, [navigate]);

  return (
    <div className={styles.focus18esimiQuasiFinal}>
      <Banner></Banner>
     <MainHeader
        sectionTitle="18esimi"
        descriptionText="Rendi il tuo 18esimo memorabile"
        contentHeroBackgroundColor="unset"
        contentHeroBackgroundImage="url('/hero-18esimi.jpg')"
        contentHeroBackgroundSize="cover"
        contentHeroBackgroundRepeat="no-repeat"
        contentHeroBackgroundPosition="top"
        chevronDownUndefinedGlyColor="#cea350"
        buttonColor="#fff"
        contentHeroDisplay="inline-block"
        onButtonClick={onButton1Click}
      />
      <div className={styles.toKnow}>
        <div className={styles.cosaDeviSapereParent}>
          <h2 className={styles.cosaDeviSapere}>{`Cosa devi sapere `}</h2>
          <p className={styles.laFestaDeiContainer}>
            <span className={styles.laFestaDei18anniUnEvent}>
              <span className={styles.laFestaDei}>
                La festa dei 18anni è un evento che
              </span>
              <span className={styles.siRicordaPer}>
                {" "}
                si ricorda per tutta la vita
              </span>
              <span>{`, che segna il passaggio alla fase adulta della vita. `}</span>
            </span>
            <span className={styles.laFestaDei18anniUnEvent}>
              <span>
                E, per questo, fondamentale affidarsi ad un professionista per
                gestire la componente musicale per il tuo evento.
              </span>
            </span>
            <span className={styles.laFestaDei18anniUnEvent}>
              <span>{`Cosi facendo, `}</span>
              <span className={styles.siRicordaPer}>
                renderai il tuo 18esimo memorabile per te ed i tuoi amici.
              </span>
            </span>
          </p>
        </div>
      </div>
      <EventMusicOptions
        eventServicesDescription="Totale libertà di poter scegliere la playlist assieme e costruire i momenti dell’evento assieme"
        musicFlexibility="Aggiornamento costante con la nuova musica appena uscita "
      />
      <ContactForm
        onFrameContainerClick={onFrameContainerClick}
        onButton1Click={onButton2Click}
        onButton2Click={onButton1Click}
      />
      <Prefooter1 onButtonClick={onButton3Click} />
      <Footer1 footerHeight="unset" />
    </div>
  );
};

export default Focus18esimiQuasiFinal;
