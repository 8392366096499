import styles from "./EventMusicOptions.module.css";

const EventMusicOptions = ({ eventServicesDescription, musicFlexibility }) => {
  return (
    <div className={styles.me}>
      <div className={styles.cosaInParticolareMiRappresParent}>
        <h2 className={styles.cosaInParticolare}>
          Cosa in particolare mi rappresenta
        </h2>
        <div className={styles.parent}>
          <div className={styles.div}>
            <div className={styles.child} />
            <img className={styles.item} alt="" src="/polygon-1.svg" />
            <p className={styles.dispongoDiTuttiContainer}>
              <span className={styles.dispongoDiTuttiContainer1}>
                <span className={styles.dispongoDiTutti}>
                  Dispongo di tutti i permessi
                </span>
                <span className={styles.dispongoDiTutti}>
                  Siae e gli strumenti necessari a garantire la qualita del
                  servizio
                </span>
              </span>
            </p>
          </div>
          <div className={styles.div1}>
            <div className={styles.inner} />
            <img className={styles.item} alt="" src="/polygon-1.svg" />
            <p className={styles.possibilitDiEffettuare}>
              {eventServicesDescription}
            </p>
          </div>
          <div className={styles.div1}>
            <div className={styles.inner} />
            <img className={styles.item} alt="" src="/polygon-1.svg" />
            <p className={styles.flessibilitSuiGeneri}>{musicFlexibility}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventMusicOptions;
