// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from "@firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDsfk_86Fcc9ZWoP3v9VgLpxKRmeoYcvi8",
  authDomain: "lorenzogorettisitotesi.firebaseapp.com",
  projectId: "lorenzogorettisitotesi",
  storageBucket: "lorenzogorettisitotesi.firebasestorage.app",
  messagingSenderId: "759535750017",
  appId: "1:759535750017:web:7b0e8687bfed0f096ef680",
  measurementId: "G-LELQEWDJT3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
const analytics = getAnalytics(app);

