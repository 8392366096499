import { useCallback } from "react";
import {useNavigate } from "react-router-dom";
import MainHeader from "../components/MainHeader";
import ServiceCard from "../components/ServiceCard";
import ValueCard from "../components/ValueCard";
import ReviewCardForm from "../components/ReviewCardForm";
import Property1Variant from "../components/Property1Variant";
import Prefooter1 from "../components/Prefooter";
import Footer1 from "../components/Footer";
import styles from "./ServiziQuasiFinal.module.css";
import Navbar from "../components/Navbar";
import Banner from "../components/banner";

const ServiziQuasiFinal = () => {
  const navigate = useNavigate();

  const onButtonClick = useCallback(() => {
    navigate("/contattiquasifinal");
  }, [navigate]);

  return (
    <div className={styles.serviziQuasiFinal}>
      <Banner></Banner>
      <MainHeader
        sectionTitle="Servizi"
        descriptionText="Qui puoi trovare tutto quello che stai cercando"
        contentHeroBackgroundColor="#222"
        contentHeroBackgroundImage="unset"
        contentHeroBackgroundSize="unset"
        contentHeroBackgroundRepeat="unset"
        contentHeroBackgroundPosition="unset"
        chevronDownUndefinedGlyColor="#cea350"
        buttonColor="#fff"
        contentHeroDisplay="inline-block"
        onButtonClick={onButtonClick}
      />
      <ServiceCard />
      <ValueCard />
      <ReviewCardForm />
      <Prefooter1 onButtonClick={onButtonClick} />
      <Footer1 footerHeight="unset" />
    </div>
  );
};

export default ServiziQuasiFinal;
