import { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import DropdownmenuServizi from "./DropdownmenuServizi";
import PortalPopup from "./PortalPopup";
import Navbar from "./Navbar";
import styles from "./MainHeader.module.css";

const MainHeader = ({
  sectionTitle,
  descriptionText,
  contentHeroBackgroundColor,
  contentHeroBackgroundImage,
  contentHeroBackgroundSize,
  contentHeroBackgroundRepeat,
  contentHeroBackgroundPosition,
  chevronDownUndefinedGlyColor,
  buttonColor,
  contentHeroDisplay,
  onButtonClick,
}) => {
  const [isDropdownmenuServiziPopupOpen, setDropdownmenuServiziPopupOpen] =
    useState(false);
  const heroGallleryStyle = useMemo(() => {
    return {
      backgroundColor: contentHeroBackgroundColor,
      backgroundImage: contentHeroBackgroundImage,
      backgroundSize: contentHeroBackgroundSize,
      backgroundRepeat: contentHeroBackgroundRepeat,
      backgroundPosition: contentHeroBackgroundPosition,
    };
  }, [
    contentHeroBackgroundColor,
    contentHeroBackgroundImage,
    contentHeroBackgroundSize,
    contentHeroBackgroundRepeat,
    contentHeroBackgroundPosition,
  ]);

  const serviziStyle = useMemo(() => {
    return {
      color: chevronDownUndefinedGlyColor,
    };
  }, [chevronDownUndefinedGlyColor]);

  const galleryStyle = useMemo(() => {
    return {
      color: buttonColor,
    };
  }, [buttonColor]);

  const button1Style = useMemo(() => {
    return {
      display: contentHeroDisplay,
    };
  }, [contentHeroDisplay]);

  return (
    <div className={styles.heroGalllery} style={heroGallleryStyle}>
      <div className={styles.contentHero}>
        <Navbar
          navbarMargin="unset"
          navbarBackgroundColor="unset"
          navbarAlignSelf="stretch"
          navbarWidth="unset"
          chiSonoColor="#fff"
          serviziColor="#fff"
          galleryColor="#fff"
          hamburgerIconBackgroundColor="transparent"
          onButtonClick={onButtonClick}
        />
        <div className={styles.rectangleParent}>
          <div className={styles.frameChild} />
          <h1 className={styles.gallery}>{sectionTitle}</h1>
          <p className={styles.quiPuoiTrovare}>{descriptionText}</p>
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
