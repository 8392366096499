import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Property1Variant from "./Property1Variant";
import styles from "./RepertorioCard.module.css";

const RepertorioCard = () => {
  const navigate = useNavigate();

  const onButtonClick = useCallback(() => {
    navigate("/contattiquasifinal");
  }, [navigate]);

  return (
    <div className={styles.repertorio}>
      <div className={styles.contentRep}>
        <h2 className={styles.ilMioRepertorio}>Il mio repertorio</h2>
        <div className={styles.contentRepertorio}>
          <div className={styles.containerImg}>
          <img
            className={styles.contentRepertorioChild}
            alt=""
            src="/img-repertorio@2x.png"
          />
          </div>
          <p className={styles.grazieAiMieContainer}>
            <span className={styles.grazieAiMie}>
              Grazie ai mie anni di esperienza, ho maturato una buona
              dimestichezza con moltissimi generi musicali.
            </span>
            <span className={styles.grazieAiMie}>Questi in particolare:</span>
            <span className={styles.grazieAiMie}>&nbsp;</span>
            <ul className={styles.rockMetalPunkDiscoAnn}>
              <li className={styles.rockMetal}>{`Rock , metal, punk, `}</li>
              <li className={styles.rockMetal}>Disco anni 80</li>
              <li className={styles.rockMetal}>Disco anni 90</li>
              <li className={styles.rockMetal}>Revival anni 70</li>
              <li className={styles.rockMetal}>Commerciale, hit del momento</li>
              <li className={styles.rockMetal}>Revival anni 2000</li>
              <li className={styles.rockMetal}>Musica elettronica, EDM</li>
            </ul>
          </p>
        </div>
        <Property1Variant
          button="Scopriamolo insieme "
          property1Variant2Border="none"
          property1Variant2BackgroundColor="#cea350"
          property1Variant2Width="unset"
          buttonDisplay="inline-block"
          buttonTextDecoration="unset"
          buttonFontWeight="unset"
          onButtonClick={onButtonClick}
        />
      </div>
    </div>
  );
};

export default RepertorioCard;
