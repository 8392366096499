import { useMemo } from "react";
import styles from "./FormGalleryMatrimoni.module.css";

const FormGalleryMatrimoni = ({
  eventTypeName,
  videoSource,
  videoSourceSecond,
  videoSourceThird,
  propPadding,
  propDisplay,
  propWidth,
}) => {
  const galleryMatrimoniStyle = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  const matrimoniStyle = useMemo(() => {
    return {
      display: propDisplay,
      width: propWidth,
    };
  }, [propDisplay, propWidth]);

  return (
    <div className={styles.galleryMatrimoni} style={galleryMatrimoniStyle}>
      <div className={styles.galleryMatrimoni1}>
        <h1 className={styles.title}>Aziendali</h1>
        <div className={styles.frameParent}>
          <img
            className={styles.frameChild}
            alt=""
            src="/campari-img.png"
          />
          <video className={styles.wrapper} controls>
            <source src={videoSource}/>
          </video>
        </div>
        <div className={styles.frameGroup}>
          <video className={styles.wrapper} controls>
            <source src={videoSourceSecond}/>
          </video>
          <video className={styles.wrapper} controls>
            <source src={videoSourceThird}/>
          </video>
        </div>
      </div>
    </div>
  );
};

export default FormGalleryMatrimoni;
