import styles from "./CardWithPermissionsAndQualityG.module.css";

const CardWithPermissionsAndQualityG = ({
  permissionDescription,
  serviceQualityDescription,
}) => {
  return (
    <div className={styles.div}>
      <div className={styles.child} />
      <img className={styles.item} alt="" src="/polygon-1.svg" />
      <p className={styles.dispongoDiTuttiContainer}>
        <span className={styles.dispongoDiTuttiContainer1}>
          <span className={styles.dispongoDiTutti}>
            {permissionDescription}
          </span>
          <span className={styles.dispongoDiTutti}>
            {serviceQualityDescription}
          </span>
        </span>
      </p>
    </div>
  );
};

export default CardWithPermissionsAndQualityG;
