import CardWithPermissionsAndQualityG from "./CardWithPermissionsAndQualityG";
import styles from "./FilteredCardForm1.module.css";

const FilteredCardForm1 = () => {
  return (
    <div className={styles.me}>
      <div className={styles.cosaInParticolareMiRappresParent}>
        <h2 className={styles.cosaInParticolare}>
          Cosa in particolare mi rappresenta
        </h2>
        <div className={styles.parent}>
          <CardWithPermissionsAndQualityG
            permissionDescription="Dispongo di tutti i permessi"
            serviceQualityDescription="Siae e gli strumenti necessari a garantire la qualita del servizio"
          />
          <div className={styles.div}>
            <div className={styles.child} />
            <img className={styles.item} alt="" src="/polygon-1.svg" />
            <p className={styles.flessibilitSuiGeneri}>
              Flessibilità sui generi musicali e sulla possibilità di effettuare
              trasferte
            </p>
          </div>
          <CardWithPermissionsAndQualityG
            permissionDescription="Possibilità di effettuare il necessario numero di incontri prima dell’evento per garantirne la migliore riuscita."
            serviceQualityDescription="Inoltre avrai un contatto diretto con me per chiarire ogni tuo dubbio"
          />
        </div>
      </div>
    </div>
  );
};

export default FilteredCardForm1;
