import styles from "./DropdownmenuServizi.module.css";

const DropdownmenuServizi = ({ onClose }) => {
  return (
    <div className={styles.dropdownmenuServizi}>
      <div className={styles.matrimoniParent}>
        <div className={styles.matrimoni}>Matrimoni</div>
        <div className={styles.matrimoni}>18esimi</div>
        <div className={styles.matrimoni}>Eventi aziendali</div>
        <div className={styles.matrimoni}>Eventi privati</div>
      </div>
    </div>
  );
};

export default DropdownmenuServizi;
