import styles from "./ValueCard.module.css";

const ValueCard = () => {
  return (
    <div className={styles.iMieiValoriWrapper}>
      <div className={styles.iMieiValori}>
        <h2 className={styles.iMieiValori1}>I miei valori</h2>
        <div className={styles.soddisfazioneDelClienteParent}>
          <p className={styles.soddisfazioneDelClienteContainer}>
            <ul className={styles.soddisfazioneDelCliente}>
              <li className={styles.nopunti}>Soddisfazione del cliente</li>
            </ul>
          </p>
          <p className={styles.ricercaMusicaleDettagliataContainer}>
            <ul className={styles.soddisfazioneDelCliente}>
              <li className={styles.nopunti}>Ricerca musicale dettagliata per ogni evento</li>
            </ul>
          </p>
          <p className={styles.ricercaMusicaleDettagliataContainer}>
            <ul className={styles.soddisfazioneDelCliente}>
              <li className={styles.nopunti}>Costruzione dell’evento con il cliente</li>
            </ul>
          </p>
          <p className={styles.ricercaMusicaleDettagliataContainer}>
            <ul className={styles.soddisfazioneDelCliente}>
              <li className={styles.nopunti}>Disponibilità di fare incontri prima dell’evento</li>
            </ul>
          </p>
          <p className={styles.ricercaMusicaleDettagliataContainer}>
            <ul className={styles.soddisfazioneDelCliente}>
              <li className={styles.nopunti}>Voglia di fare una bella festa</li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ValueCard;
