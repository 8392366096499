import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "./carouselcompanies.css";

const MobileCarousel = () => {
  return (
    <Carousel showArrows={true} showThumbs={false} showStatus={false}>
      <div className='container-carousel'>
        <img className='lefollie-text' src="/logo-le-follie.png"></img>
      </div>
      <div className='container-carousel'>
        <img className='lefollie-text' src="/logo-moda.png"></img>
      </div>
      <div className='container-carousel'>
        <img className='lefollie-text' src="/logo-just-people.png"></img>
      </div>
      <div className='container-carousel'>
        <img className='lefollie-text' src="/logo-moregallo.png"></img>
      </div>
      <div className='container-carousel'>
        <img className='lefollie-text' src="/logo-papaya.png"></img>
      </div>
      {/* Aggiungi qui altre immagini */}
    </Carousel>
  );
};

export default MobileCarousel;
