import styles from "./RispostaPagamenti.module.css";

const RispostaPagamenti = ({ onClose}) => {
  return (
    <div className={styles.rispostaPagamenti}>
      <div className={styles.iMetodiDiPagamentoAccetatiWrapper}>
        <div className={styles.iMetodiDi}>I metodi di pagamento accetati sono paypal, bonifico bancario o in contanti.</div>
      </div>
    </div>
  );
};

export default RispostaPagamenti;
