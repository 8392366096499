import CardWithPermissionsAndQualityG from "./CardWithPermissionsAndQualityG";
import styles from "./FilteredCardForm.module.css";

const FilteredCardForm = () => {
  return (
    <div className={styles.me}>
      <div className={styles.cosaInParticolareMiRappresParent}>
        <h1 className={styles.cosaInParticolare}>
          Cosa in particolare mi rappresenta
        </h1>
        <div className={styles.parent}>
          <CardWithPermissionsAndQualityG
            permissionDescription="Dispongo di tutti i permessi"
            serviceQualityDescription="Siae e gli strumenti necessari a garantire la qualita del servizio"
          />
          <CardWithPermissionsAndQualityG
            permissionDescription="Possibilità di effettuare il necessario numero di incontri prima dell’evento per garantirne la migliore riuscita."
            serviceQualityDescription="Inoltre avrai un contatto diretto con me per chiarire ogni tuo dubbio"
          />
          <CardWithPermissionsAndQualityG
            permissionDescription="Possibilità di scegliere assieme la scaletta e i vari momenti dell’evento come taglio della torta, ballo degli sposi, ingresso aperitivo."
            serviceQualityDescription="Ogni fase avrà la sua musica"
          />
        </div>
        <p className={styles.nelPrezzoDelContainer}>
          <span className={styles.nelPrezzoDelWeddingPack}>
            <span>{`Nel prezzo del wedding pack è incluso il servizio dj per tutta la durata dell’evento, con console, illuminazione,  attrezzatura, audio e tutto il necessario per la buona riuscita dell’evento.  `}</span>
          </span>
          <span className={styles.inoltreConsigliatoMettersi}>
            <span>
              È, inoltre, consigliato mettersi in contatto il prima possibile
              per fissare la data dell’evento
            </span>
          </span>
        </p>
      </div>
    </div>
  );
};

export default FilteredCardForm;
