import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Property1Variant from "./Property1Variant";
import styles from "./ServiziCard.module.css";

const ServiziCard = () => {
  const navigate = useNavigate();
  const onButtonClick = useCallback(() => {
    navigate("/serviziquasifinal");
  }, [navigate]);

  return (
    <div className={styles.serviziHomepage}>
      <div className={styles.servizi}>
        <h2 className={styles.servizi1}>Servizi</h2>
        <div className={styles.content}>
          <div className={styles.matrimoni}>
            <b className={styles.eventiAziendali}>Matrimoni</b>
          </div>
          <div className={styles.aziendali}>
            <b className={styles.eventiAziendali}>Eventi aziendali</b>
          </div>
          <div className={styles.esimi}>
            <b className={styles.eventiAziendali}>18esimi</b>
          </div>
        </div>
        <Property1Variant
          button="I mie Servizi"
          property1Variant2Border="none"
          property1Variant2BackgroundColor="#cea350"
          property1Variant2Width="unset"
          buttonDisplay="inline-block"
          buttonTextDecoration="unset"
          buttonFontWeight="unset"
          onButtonClick={onButtonClick}
        />
      </div>
    </div>
  );
};

export default ServiziCard;
