import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import MainHeader from "../components/MainHeader";
import FilteredCardForm from "../components/FilteredCardForm";
import ContactForm from "../components/ContactForm";
import Prefooter1 from "../components/Prefooter";
import Footer1 from "../components/Footer";
import styles from "./FocusMatrimoniQuasiFinal.module.css";
import Banner from "../components/banner";

const FocusMatrimoniQuasiFinal = () => {
  const navigate = useNavigate();

  const onButtonClick = useCallback(() => {
    navigate("/contattiquasifinal");
  }, [navigate]);

  const onFrameContainerClick = useCallback(() => {
    // Please sync "contatti" to the project
  }, []);

  const onButton2Click = useCallback(() => {
    navigate("/galleryquasifinal");
  }, [navigate]);

  const onButton1Click = useCallback(() => {
    navigate("/contattiquasifinal");
  }, [navigate]);

  const onButton3Click = useCallback(() => {
    navigate("/contattiquasifinal");
  }, [navigate]);

  return (
    <div className={styles.focusMatrimoniQuasiFinal}>
      <Banner></Banner>
      <MainHeader
        sectionTitle="Matrimoni"
        descriptionText="Rendi il tuo matrimonio memorabile"
        contentHeroBackgroundColor="unset"
        contentHeroBackgroundImage="url('/hero-matrimoni.jpg')"
        contentHeroBackgroundSize="cover"
        contentHeroBackgroundRepeat="no-repeat"
        contentHeroBackgroundPosition="top"
        chevronDownUndefinedGlyColor="#cea350"
        buttonColor="#fff"
        contentHeroDisplay="inline-block"
        onButtonClick={onButtonClick}
      />
      <div className={styles.toKnow}>
        <div className={styles.cosaDeviSapereParent}>
          <h2 className={styles.cosaDeviSapere}>{`Cosa devi sapere `}</h2>
          <p className={styles.ilMatrimonioSiContainer}>
            <span className={styles.ilMatrimonioSiPuDefinire}>
              <span
                className={styles.ilMatrimonioSi}
              >{`Il matrimonio si può definire come `}</span>
              <span className={styles.unoDeiGiorni}>
                uno dei giorni più importanti della vita di una persona
              </span>
              <span>. Nulla deve andare storto.</span>
            </span>
            <span className={styles.ilMatrimonioSiPuDefinire}>
              <span>{`Per questo è fondamentale affidarsi ad `}</span>
              <span className={styles.unoDeiGiorni}>
                un esperto che sappia creare la giusta atmosfera
              </span>
              <span>{`, assicurando così la buona riuscita del matrimonio. `}</span>
            </span>
            <span className={styles.ilMatrimonioSiPuDefinire}>
              <span>{`Se ciò che cerchi è `}</span>
              <span className={styles.unoDeiGiorni}>
                qualità e professionalità
              </span>
              <span className={styles.ilMatrimonioSi}>
                {" "}
                sei nel posto giusto.
              </span>
            </span>
          </p>
        </div>
      </div>
      <FilteredCardForm />
      <ContactForm
        onFrameContainerClick={onFrameContainerClick}
        onButton1Click={onButton2Click}
        onButton2Click={onButton1Click}
      />
      <Prefooter1 onButtonClick={onButton3Click} />
      <Footer1 footerHeight="unset" />
    </div>
  );
};

export default FocusMatrimoniQuasiFinal;
