import styles from "./RispostaPagamenti.module.css";

const RispostaTipologiaServizi = ({ onClose }) => {
  return (
    <div className={styles.rispostaPagamenti}>
    <div className={styles.iMetodiDiPagamentoAccetatiWrapper}>
      <div className={styles.iMetodiDi}>Matrimoni, feste aziendali, 18esimi.
Si consiglia di vedere la pagina servizi per maggiori info</div>
    </div>
  </div>
  );
};

export default RispostaTipologiaServizi;
