import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import HomeQuasiFinal from "./pages/HomeQuasiFinal";
import ContattiQuasiFinal from "./pages/ContattiQuasiFinal";
import FocusMatrimoniQuasiFinal from "./pages/FocusMatrimoniQuasiFinal";
import Focus18esimiQuasiFinal from "./pages/Focus18esimiQuasiFinal";
import FocusAziendaliQuasiFinal from "./pages/FocusAziendaliQuasiFinal";
import FocusPrivatiQuasiFinal from "./pages/FocusPrivatiQuasiFinal";
import ServiziQuasiFinal from "./pages/ServiziQuasiFinal";
import GalleryQuasiFinal from "./pages/GalleryQuasiFinal";
import ChiSonoQuasiFinal from "./pages/ChiSonoQuasiFinal";
import ThankYouPageQuasiFinal from "./pages/ThankYouPageQuasiFinal";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/contattiquasifinal":
        title = "";
        metaDescription = "";
        break;
      case "/focusmatrimoniquasifinal":
        title = "";
        metaDescription = "";
        break;
      case "/focus18esimiquasifinal":
        title = "";
        metaDescription = "";
        break;
      case "/focusaziendaliquasifinal":
        title = "";
        metaDescription = "";
        break;
      case "/focusprivatiquasifinal":
        title = "";
        metaDescription = "";
        break;
      case "/serviziquasifinal":
        title = "";
        metaDescription = "";
        break;
      case "/galleryquasifinal":
        title = "";
        metaDescription = "";
        break;
      case "/chisonoquasifinal":
        title = "";
        metaDescription = "";
        break;
      case "/thankyoupagequasifinal":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<HomeQuasiFinal />} />
      <Route path="/contattiquasifinal" element={<ContattiQuasiFinal />} />
      <Route
        path="/focusmatrimoniquasifinal"
        element={<FocusMatrimoniQuasiFinal />}
      />
      <Route
        path="/focus18esimiquasifinal"
        element={<Focus18esimiQuasiFinal />}
      />
      <Route
        path="/focusaziendaliquasifinal"
        element={<FocusAziendaliQuasiFinal />}
      />
      <Route
        path="/focusprivatiquasifinal"
        element={<FocusPrivatiQuasiFinal />}
      />
      <Route path="/serviziquasifinal" element={<ServiziQuasiFinal />} />
      <Route path="/galleryquasifinal" element={<GalleryQuasiFinal />} />
      <Route path="/chisonoquasifinal" element={<ChiSonoQuasiFinal />} />
      <Route
        path="/thankyoupagequasifinal"
        element={<ThankYouPageQuasiFinal />}
      />
    </Routes>
  );
}
export default App;
