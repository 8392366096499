import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Property1Variant from "./Property1Variant";
import styles from "./ContactFormCard.module.css";
import React, {useRef} from "react";
import { firestore } from "../firebase";
import {addDoc, collection} from "@firebase/firestore"

const ContactFormCard = () => {
  const messageRef = useRef();
  const nameRef = useRef();
  const telRef = useRef();
  const mailRef = useRef();

  const handleSave = async (e) => {
    e.preventDefault();

    // Validazione base
    if (!messageRef.current.value || !nameRef.current.value || !telRef.current.value || !mailRef.current.value) {
      console.log("Compila tutti i campi!");
      return;
    }

    let data = {
      message: messageRef.current.value,
      name: nameRef.current.value,
      tel: telRef.current.value,
      mail: mailRef.current.value,
    };

    try {
      // URL della funzione proxy distribuita su Firebase
      const proxyUrl = "https://us-central1-lorenzogorettisitotesi.cloudfunctions.net/firestoreProxy";

      // Chiamata a Firestore attraverso la funzione proxy
      const response = await fetch(proxyUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        navigate("/thankyoupagequasifinal");
      } else {
        console.log("Errore nell'aggiunta del documento:", await response.json());
      }
    } catch (e) {
      console.log("Errore nella richiesta:", e);
    }
  };
  const navigate = useNavigate();


  return (
    <div className={styles.contactContainerHomepage}>
      <form className={styles.contactForm} onSubmit={handleSave}>
        <h2 className={styles.cosaStaiAspettando}>
          Cosa stai aspettando? Ora tocca a te
        </h2>
        <div className={styles.mandaciLaTuaRichiestaParent}>
          <h3 className={styles.mandaciLaTua}>Mandaci la tua richiesta!</h3>
          <p className={styles.nonVediamoLora}>
            Non vediamo l’ora del tuo prossimo evento.
          </p>
          <div className={styles.ilTuoNomeLaTuaAziendaParent}>
            <p className={styles.ilTuoNome}>Il tuo nome / La tua azienda</p>
            <input
              className={styles.frameChild}
              placeholder="Nome/Azienda.."
              type="text"
              ref={nameRef}
            />
          </div>
          <div className={styles.frameParent}>
            <div className={styles.emailParent}>
              <p className={styles.ilTuoNome}>Email</p>
              <input
                className={styles.frameChild}
                placeholder="la tua email"
                type="email"
                ref={mailRef}
              />
            </div>
            <div className={styles.emailParent}>
              <p className={styles.ilTuoNome}>Telefono</p>
              <input
                className={styles.frameChild}
                placeholder="Il tuo numero.."
                type="number"
                ref={telRef}
              />
            </div>
          </div>
          <div className={styles.laTuaRichiestaParent}>
            <p className={styles.ilTuoNome}>La tua richiesta</p>
            <input
              className={styles.rectangleInput}
              placeholder="Dicci di cosa hai bisogno.."
              type="text"
              ref={messageRef}
            />
          </div>
        <button  className={styles.buttonForm} type="submit">Mandaci la tua richiesta</button>
        </div>
      </form>
    </div>
  );
};

export default ContactFormCard;
